<template>
  <UiPopup
    :model-value="modelValue"
    title="Delete user"
    description="This user has active leads. Determine where to move the leads before deleting the user."
    primary-button-text="Delete user"
    secondary-button-text="Cancel"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="deleteUser"
  >
    <div class="transition-all duration-200">
      <UiLoader v-if="loading" />
      <form v-else ref="form" @submit.prevent>
        <UiInputRadio id="choose_method" v-model="method" :items="methods" class="mb-4" vertical>
          <template #custom="{ item }">
            <div v-if="item.value === DEFAULT_METHOD && method === DEFAULT_METHOD" class="mb-4 w-full">
              <UiInputSelect
                v-model="data.agentId"
                :items="agentsItems"
                name="Agents"
                group
                avatar
                placeholder="Choose a user"
                :error="useGetFieldErrors(v$, ['agentId'])"
              />
            </div>
          </template>
        </UiInputRadio>
      </form>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useUiStore } from '@/store/ui'
import type { User, Stage, InputItem } from '@/types'
import { POOL_REASONS, POOL_STAGES, PIPELINES } from '@/constants'
const uiStore = useUiStore()

const DEFAULT_METHOD = 'assign'
const POOL_METHOD = 'pool'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  user: User
  leads: number[]
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  leads: () => [],
})
const loading = ref(false)

const method = ref(DEFAULT_METHOD)
const methods = [
  { text: 'Assign to user', value: DEFAULT_METHOD },
  { text: 'Release to Pool', value: POOL_METHOD },
]

const data = ref({
  agentId: '',
})
const poolReason = ref<number | null | undefined>(null)
const agentsItems = ref<InputItem[]>([])

const rules = computed(() => ({
  agentId: { required: helpers.withMessage('The agent is required', required) },
}))

const v$ = useVuelidate(rules, data)

onNuxtReady(async () => await Promise.all([getUsers(), getReasons()]))

const getUsers = async () => {
  loading.value = true
  const users = await useGetUsersItems(true)
  agentsItems.value = users.filter((user) => user.value !== props.user.id)
  loading.value = false
}

const getReasons = async () => {
  loading.value = true
  const pool = await useGetPipelineByCode(PIPELINES.POOL)
  const reasons = pool.stages?.find((s: Stage) => s.code === POOL_STAGES.VERIFIED)?.steps
  poolReason.value = reasons?.find((s: Stage) => s.code === POOL_REASONS.USER_DELETION)?.id
  loading.value = false
}

const deleteUser = async () => {
  const isValid = method.value === DEFAULT_METHOD ? await v$.value.$validate() : true
  if (isValid) {
    loading.value = true
    try {
      if (method.value === DEFAULT_METHOD) {
        await useAssignLeadsToAgent(data.value.agentId, props.leads)
      } else {
        await useBulkMoveLeadToPool({
          pipeline_step_id: poolReason.value,
          lead_ids: props.leads,
        })
      }
      await useDeleteUser(Number(props.user.id))
      uiStore.showSnackBanner(`User ${props.user.name} was deleted and moved to users archive.`)
      emits('input')
      emits('update:modelValue', false)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
